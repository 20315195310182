<template>
  <div>
    <loadingComponent
      v-if="dialog == true"
      @closeDialog="dialog = $event"
      :messageDialogeProps="messageDialogeError"
      :dialogProps="dialog"
      :loadingProps="loading"
      :errorProps="error"
    />
    <v-dialog v-model="dialogMint" persistent max-width="1224" min-height="250">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar :color="$colors[0]" class="text-white" dark>
            <p class="text-h6 mb-0">Edition contrat MINT {{ donner.type }}</p>
          </v-toolbar>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-actions>
            <div class="d-flex flex-row">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <p>
                  <b>N° Compteur selectionné:</b> <small>{{ numCompteur }}</small>
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                v-if="ajouterUnAutreCompteur == true"
              >
                <v-autocomplete
                  v-model="selectedCompteur"
                  :items="listCompteur"
                  :loading="isLoading"
                  hide-no-data
                  hide-selected
                  label="Autre compteur"
                  placeholder="Ajouter un compteur"
                  prepend-icon="mdi-account-search-outline"
                  chips
                  deletable-chips
                  multiple
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </div>
          </v-card-actions>
        </v-form>
        <v-form ref="informationsInterlocuteurTechnique">
          <h6 class="ml-4">Informations Interlocuteur technique</h6>
          <div class="d-flex flex-row">
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurTechnique.nom"
                dense
                outlined
                label="Nom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurTechnique.prenom"
                dense
                outlined
                label="Prenom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurTechnique.numTel"
                dense
                outlined
                label="Telephone"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurTechnique.email"
                dense
                outlined
                label="Email"
              ></v-text-field>
            </v-col>
          </div>
          <h6 class="ml-4">Informations Interlocuteur contractuel</h6>
          <div class="d-flex flex-row">
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurContractuel.nom"
                dense
                outlined
                label="Nom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurContractuel.prenom"
                dense
                outlined
                label="Prenom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurContractuel.numTel"
                dense
                outlined
                label="Telephone"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                :rules="[rules.required]"
                v-model="interlocuteurContractuel.email"
                dense
                outlined
                label="Email"
              ></v-text-field>
            </v-col>
          </div>
          <h6 class="ml-4">Information Cee</h6>
          <div class="d-flex flex-row">
            <v-col cols="12">
              <v-radio-group
                v-model="soumis"
                row
                >
                <v-radio
                  label="CEE Soumis"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="CEE Non Soumis"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <h6 class="ml-4">Information Garanties d’Origine</h6>
          <div class="d-flex flex-row">
            <v-col cols="12">
              <v-radio-group
                v-model="garantieOrigin"
                row
                >
                <v-radio
                  label="Souhaité"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="Non Souhaité"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <h6 class="ml-4"  v-if="garantieOrigin==true">Valeur Garanties d’Origine</h6>
          <div class="d-flex flex-row" v-if="garantieOrigin==true">
            <v-col cols="12">
              <v-radio-group
                v-model="valeurGarantieOrigin"
                row
                >
                <v-radio
                  label="25%"
                  :value="25"
                ></v-radio>
                <v-radio
                  label="50%"
                  :value="50"
                ></v-radio>
                <v-radio
                  label="100%"
                  :value="100"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </div>
        </v-form>
        <v-card-actions class="d-flex flex-row justify-content-end">
          <v-btn color="error" @click="closeModal">Annuler</v-btn>
          <v-btn
            class="white--text"
            :color="$colors[0]"
            @click="createContrat"
            :disabled="isGenerateDisabled"
            >Générer le Contrat</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-btn @click.stop="openDialog" icon depressed class="ma-auto text-capitalize">
        <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import loadingComponent from "../../components/ui/loading-component";
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  layoutMultilineText,
  setFillingRgbColor,
  setFontAndSize,
} from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import dialogeModal from "../../components/ui/loading-component";
import axios from "axios";
export default {
  name: "EkwateurContrat",
  components: { loadingComponent: loadingComponent },
  props: {
    donner: null,
    numCompteur: null,
    societe: null,
    donnerEnedis: null,
    optionTarifaire: null,
    listCompteur: [],
  },
  data() {
    return {
      interlocuteurContractuel: {
        nom: "",
        prenom: "",
        numTel: "",
        email: "",
      },
      interlocuteurTechnique: {
        nom: "",
        prenom: "",
        numTel: "",
        email: "",
      },
      loadingTableHistorique: false,
      fieldhistoriqueDeCotation: [
        { key: "numCompteur", label: "Numéro de compteur" },
        { key: "type", label: "Option tarifaire" },
        { key: "kva", label: "KVA" },
        { key: "dateDebut", label: "Début de debut" },
        //{key:"conso",label:"Consommation en (Mwh)"},
        { key: "consoHPH", label: "HPH/HP/BASE" },
        { key: "consoHCH", label: "HCH/HC" },
        { key: "consoHPE", label: "HPE" },
        { key: "consoHCE", label: "HCE" },
        { key: "consoPOINTE", label: "POINTE" },
        { key: "action", label: "Action" },
      ],
      soumis: true,
      garantieOrigin: false,
      valeurGarantieOrigin: 25,
      dialog: false,
      error: false,
      title: "",
      selectedCompteur: [],
      listCompteurResult: [],
      loading: false,
      messageDialogeError: "",
      messageDialogeSucces: "",
      isGenerateDisabled: false,
      dialogMint: false,
      ajouterUnAutreCompteur: false,
      isLoading: false,
      optionsTarifaires: [
        "BTINFCU4",
        "BTINFCUST",
        "BTINFMU4",
        "BTINFMUDT",
        "BTINFLU",
        "BTSUPCU4",
        "BTSUPLU4",
        "HTACU5",
        "HTACUPM5",
        "HTALU5",
        "HTALUPM5",
      ],
      data: {
        dateAjourdhui:
          new Date().getDate() +
          "/" +
          (parseInt(new Date().getMonth()) + 1) +
          "/" +
          new Date().getFullYear(),
        dateDebut: "10/10/2020",
        dateFin: "10/10/2022",
        pdl: "14552022144555",
        siret: "11111111111111",
        codeNaf: "1212Z",
        adresseSociete: "199 RUE, 12554 commune d'atte",
        adresseCompteur: "199 RUE, 12554 commune d'atte",
        codePostaleCompteur: "",
        voieCompteur: "",
        communeCompteur: "",
        codePostaleSociete: "",
        voieSociete: "",
        communeSociete: "",

        codePostale: "",
        nom: "chrif",
        prenom: "slimen",
        email: "slimenc6@gmail",
        numTele: "0755448899",
        consommationAnuuelle: "377.20",
        typeCompteur: "BTINFMUDT",
        kva: "24",
        raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
        nbrMonth: "2",
        cabase: "",
        cahp: "",
        cahc: "",
      },
      rules: {
        isNumberOrFloat: (v) =>
          /^[0-9]+(\.\d+)?$/.test(v) || "Ce champ n'accepte que les chiffres",
        required: (v) => !!v || v?.length > 0 || "Ce champ est obligatoire",
      },
      required(fieldName) {
        return (value) => {
          if (
            (value.toString().length > 0 || value >= 0) &&
            value.toString() != "" &&
            value.toString() != "undefined" &&
            value.toString() != "null"
          ) {
            return true;
          } else {
            return `Veuillez saisir ${fieldName}`;
          }
        };
      },
      greaterThanZero() {
        return (value) => value > 0 || `Valeur doit étre superieur à zero`;
      },
    };
  },
  watch: {
    selectedCompteur(newVal, oldVal) {
      if (newVal.length == 0) {
        this.isGenerateDisabled = false;
      } else if (newVal.length != oldVal.length) {
        this.isGenerateDisabled = true;
        var index = -1;
        for (var i = 0; this.listCompteurResult.length; i++) {
          index = newVal.findIndex((x) => x === this.listCompteurResult[i].numCompteur);
          if (index == -1) {
            this.listCompteurResult.splice(i, 1);
            break;
          }
        }
      }
    },
  },
  created() {
    var index = this.listCompteur.findIndex((x) => x === this.numCompteur);
    if (index > -1) {
      this.listCompteur.splice(index, 1);
    }
    this.remplireDonnerParDefaut()
  },
  methods: {
    remplireDonnerParDefaut(){
      this.interlocuteurContractuel.nom = this.societe.nom;
      this.interlocuteurContractuel.prenom = this.societe.prenom;
      this.interlocuteurContractuel.numTel = this.societe.numTele;
      this.interlocuteurContractuel.email = this.societe.AdresseEmail;

      this.interlocuteurTechnique.nom = this.societe.nom;
      this.interlocuteurTechnique.prenom = this.societe.prenom;
      this.interlocuteurTechnique.numTel = this.societe.numTele;
      this.interlocuteurTechnique.email = this.societe.AdresseEmail;
      this.soumis = this.donner.cee != "NON SOUMIS";
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    closeModal() {
      this.$refs.informationsInterlocuteurTechnique.reset();
      this.$refs.informationsInterlocuteurTechnique.resetValidation();
      this.dialogMint = false;
      this.ajouterUnAutreCompteur = false;
      this.selectedCompteur = [];
      this.listCompteurResult = [];
    },
    openDialog() {
      this.dialogMint = true;
    },
    supprimerCompteur(numComp) {
      var index = this.selectedCompteur.findIndex((x) => x === numComp);
      if (index > -1) {
        this.selectedCompteur.splice(index, 1);
      }
      index = this.listCompteurResult.findIndex((x) => x.numCompteur === numComp);
      if (index > -1) {
        this.listCompteurResult.splice(index, 1);
      }
      index = this.listCompteur.findIndex((x) => x === numComp);
      if (index == -1) {
        this.listCompteur.push(numComp);
      }
    },
    calculeSommeCotation(donneDeCotation) {
      if (donneDeCotation.parametreCompteur == "C3") {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAP)) {
            pointe = parseFloat(donneDeCotation.CAP);
          } else {
            pointe = 0;
          }
        } catch (error) {
          pointe = 0;
        }
        donneDeCotation.HPH = hph;
        donneDeCotation.HCH = hch;
        donneDeCotation.HPE = hpe;
        donneDeCotation.HCE = hce;
        donneDeCotation.POINTE = pointe;
        donneDeCotation.conso = hch + hph + hce + hpe + pointe;
        donneDeCotation.CAR = hch + hph + hce + hpe + pointe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe + pointe;
      } else if (
        donneDeCotation.parametreCompteur == "C4" ||
        donneDeCotation.parametreCompteur == "C5C4"
      ) {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        donneDeCotation.HPH = hph;
        donneDeCotation.HCH = hch;
        donneDeCotation.HPE = hpe;
        donneDeCotation.HCE = hce;
        donneDeCotation.conso = hch + hph + hce + hpe;
        donneDeCotation.CAR = hch + hph + hce + hpe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        var hc = 0;
        var hp = 0;
        try {
          if (!isNaN(donneDeCotation.CAHC)) {
            hc = parseFloat(donneDeCotation.CAHC);
          } else {
            hc = 0;
          }
        } catch (error) {
          hc = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHP)) {
            hp = parseFloat(donneDeCotation.CAHP);
          } else {
            hp = 0;
          }
        } catch (error) {
          hp = 0;
        }

        donneDeCotation.HP = hp;
        donneDeCotation.HC = hc;
        donneDeCotation.conso = hc + hp;
        donneDeCotation.CAR = hc + hp;
        donneDeCotation.consommationTotale = hc + hp;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        var base = 0;
        try {
          if (!isNaN(donneDeCotation.CABASE)) {
            base = parseFloat(donneDeCotation.CABASE);
          } else {
            base = 0;
          }
        } catch (error) {
          base = 0;
        }
        donneDeCotation.BASE = base;
        donneDeCotation.conso = base;
        donneDeCotation.CAR = base;
        donneDeCotation.consommationTotale = base;
      }
    },
    changeOptionCalculeBtinf(obj, newParam) {
      obj.parametreCompteur = newParam;
    },
    changeOptionTarifaire(obj) {
      var optionTarifaire = obj.optionTarifaire;
      if (optionTarifaire == "BTINFMUDT") {
        obj.parametreCompteur = "C5HP";
      }
      if (optionTarifaire == "BTINFLU" || optionTarifaire == "BTINFCUST") {
        obj.parametreCompteur = "C5BASE";
      }
      if (["BTSUPCU4", "BTSUPLU4"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C4";
      }
      if (optionTarifaire == "BTINFCU4") {
        obj.parametreCompteur = "C5BASE";
        obj.typeTraitementCompteur = "complex";
      }
      if (optionTarifaire == "BTINFMU4") {
        obj.parametreCompteur = "C5HP";
        obj.typeTraitementCompteur = "complex";
      }
      if (["HTACU5", "HTACUPM5", "HTALU5", "HTALUPM5"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C3";
        obj.typeTraitementCompteur = "simple";
      }
    },
    async openAjouterUnAutreCompteur() {
      this.ajouterUnAutreCompteur = true;
    },
    async appelleEnedis() {
      this.loadingTableHistorique = true;
      await this.getConsoAllCompteur();
      this.loadingTableHistorique = false;
    },
    async createContrat() {
      let validateTechnique = await this.$refs.informationsInterlocuteurTechnique.validate();
      if (validateTechnique) {
        this.title = "Creation de contrat";
        this.addData = false;
        this.dialog = true;
        this.error = false;
        this.loading = true;
        this.messageDialogeError = "";
        this.messageDialogeSucces = "";
        var dataToSend = {
          // typeContrat: this.donner.type,
          donner: this.donner,
          donnerEnedis: this.donnerEnedis,
          optionTarifaire: this.optionTarifaire,
          numCompteur: this.numCompteur,
          listCompteur: this.listCompteurResult,
          societe: this.societe,
          interlocuteurContractuel: this.interlocuteurContractuel,
          interlocuteurTechnique: this.interlocuteurTechnique,
          soumis: this.soumis,
          garantieOrigin: this.garantieOrigin,
          valeurGarantieOrigin: this.valeurGarantieOrigin,
        };
        await axios({
          url: 'https://editioncontrat.volto.fr/MintEnergie',
          method: "POST",
          data: dataToSend,
          headers: { "content-type": "application/json" },
        })
          .then(async (res) => {
            var doc = await PDFDocument.load(res.data);
            console.log(doc);
            doc = await doc.save();
            download(
              doc,
              this.numCompteur +
                `_MINT_${this.donner.type}_` +
                this.societe.Raison +
                "_.pdf",
              "application/pdf"
            );
          })
          .catch((err) => {
            console.log(err);
          });
        this.closeModal();
        this.dialog = false;
        this.error = false;
        this.loading = false;
        this.messageDialogeError = "";
        this.messageDialogeSucces = "";
      }
    },
    async getConsoAllCompteur() {
      this.dialog = true;
      this.loading = true;
      this.title =
        "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!";
      this.messageDialoge =
        "veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!";
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("listCompteur", this.selectedCompteur);
      formData.append("dateDebut", this.donner.dateDebut);
      formData.append("dateFin", this.donner.dateFin);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurConso/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.listCompteurResult = res.data.result;
          this.dialog = false;
          this.loading = false;
          this.isGenerateDisabled = false;
        })
        .catch((err) => {
          this.listCompteurResult = [];
          this.error = true;
          this.loading = false;
          this.messageDialogeError =
            "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!";
        });
    },
  },
};
</script>

<!-- table style -->
<style lang="scss" scoped>
.table-ekwateur-compteur {
  td {
    min-width: 105px;
  }
  ::v-deep .v-text-field__suffix {
    font-size: 10px !important;
    color: black;
  }
  ::v-deep
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 8px !important;
  }
}
</style>
